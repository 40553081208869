import React from 'react';
import { FEATURE_FLAGS, isFeatureEnabled } from '~utils/feature-helpers';
import NonFreeTrialBlocker from './NonFreeTrialBlocker';
import FreeTrialBlocker from './FreeTrialBlocker';

const IS_FREE_TRIAL_ENABLED = isFeatureEnabled(FEATURE_FLAGS.FREE_TRIAL);

const PremiumContentBlocker = () => {
  if (!IS_FREE_TRIAL_ENABLED) {
    return <NonFreeTrialBlocker />;
  }

  return <FreeTrialBlocker />;
};

export default PremiumContentBlocker;
