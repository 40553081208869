import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { calculateVideoContentType } from '~utils/playlist-helpers';
import { IN_VIDEO_POPUPS } from '~layout/InVideoBlocker/InVideoBlocker';

const Popups = loadable(() => import('~layout/Popups'));
const InVideoBlocker = loadable(() => import('~layout/InVideoBlocker'));
const MediaPlayer = loadable(() => import('~components/media-player'));
const SkipCtaButton = loadable(() => import('../../skip-cta-button'));
const SubscribeBanner = loadable(() => import('./SubscribeBanner'));

const VideoSection = ({
  isPlaying,
  seekToSecond,
  currentVideo,
  popup,
  isPopupOpen,
  title,
  author,
  prismicId,
  thumbnail,
  isSingleVideo,
  uid,
  hasPromotionalVideo,
  promoVideoId,
  labels,
  senderFullName,
  giftToken,
  isSkipPromotionalButtonOpen,
  onReady,
  onStart,
  onProgress,
  onEnded,
  handleBlockingPopupClose,
  onPromotionalProgress,
  onPromotionEnded,
  toggleVideoPlayState
}) => {
  const contentType = calculateVideoContentType(isSingleVideo, labels.isAudio);
  /**
   * A video blocker can be either inside the video or take the full screen
   * Here, let's check if the popup/blocker should be an in-video or not
   */
  const isInVideoBlocker = useMemo(() => IN_VIDEO_POPUPS.indexOf(popup) > -1, [popup]);

  return (
    <Grid container direction="row" justify="center" alignItems="stretch">
      <Grid item xs={12} sm={8} md={9}>
        {/* Video player and details */}
        <>
          {/* Container for the positioning of the "In Video Popups". This is the only way to position the soft-sale banner as a border of the video player on all desktop screens without media queries */}
          <Box position="relative" width="100%" marginTop="55px">
            <SubscribeBanner enableBanner={!popup} uid={uid} />
            {hasPromotionalVideo && (
              <MediaPlayer
                playing
                url={promoVideoId}
                isPromotionalVideo
                onEnded={onPromotionEnded}
                onProgress={onPromotionalProgress}
              />
            )}
            {!hasPromotionalVideo && (
              <MediaPlayer
                playing={isPlaying}
                seekToSecond={seekToSecond}
                url={currentVideo.trailer_wistia_url.url || currentVideo.wistia_url.url}
                onReady={onReady}
                onStart={onStart}
                onProgress={onProgress}
                onEnded={onEnded}
              />
            )}
            {isInVideoBlocker ? (
              <InVideoBlocker isPopupOpen={isPopupOpen} popup={popup} />
            ) : (
              <Popups
                which={popup}
                isPopupOpen={isPopupOpen}
                handleClose={handleBlockingPopupClose}
                playlistId={prismicId}
                playlistSlug={uid}
                thumbnail={thumbnail}
                contentType={contentType}
                playlistTitle={title}
                videoTitle={currentVideo.title}
                author={author.name}
                senderFullName={senderFullName}
                giftToken={giftToken}
                toggleVideoPlayState={toggleVideoPlayState}
              />
            )}

            {isSkipPromotionalButtonOpen && <SkipCtaButton handleClick={onPromotionEnded} />}
          </Box>
        </>
      </Grid>
    </Grid>
  );
};

VideoSection.propTypes = {
  isPlaying: PropTypes.bool.isRequired,
  seekToSecond: PropTypes.number,
  currentVideo: PropTypes.shape({
    transcript: PropTypes.shape({ html: PropTypes.string.isRequired }).isRequired,
    trailer_wistia_url: PropTypes.shape({ url: PropTypes.string.isRequired }),
    wistia_url: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
    description: PropTypes.shape({ html: PropTypes.string.isRequired }).isRequired,
    uid: PropTypes.string.isRequired,
    prismicId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired,

  onReady: PropTypes.func.isRequired,
  onStart: PropTypes.func.isRequired,
  onProgress: PropTypes.func.isRequired,
  onEnded: PropTypes.func.isRequired,
  handleBlockingPopupClose: PropTypes.func.isRequired,
  onPromotionalProgress: PropTypes.func.isRequired,
  onPromotionEnded: PropTypes.func.isRequired,
  toggleVideoPlayState: PropTypes.func.isRequired,
  popup: PropTypes.number,
  isPopupOpen: PropTypes.bool,
  title: PropTypes.string.isRequired,
  author: PropTypes.shape({ name: PropTypes.string }).isRequired,
  prismicId: PropTypes.string.isRequired,
  thumbnail: PropTypes.shape({ alt: PropTypes.string, url: PropTypes.string }).isRequired,
  isSingleVideo: PropTypes.bool.isRequired,
  uid: PropTypes.string.isRequired,
  hasPromotionalVideo: PropTypes.bool.isRequired,
  promoVideoId: PropTypes.string,
  labels: PropTypes.shape({
    isAudio: PropTypes.bool.isRequired,
    isNew: PropTypes.bool.isRequired,
    isPremium: PropTypes.bool.isRequired,
    isProducer: PropTypes.bool.isRequired
  }).isRequired,
  senderFullName: PropTypes.string,
  giftToken: PropTypes.string,
  isSkipPromotionalButtonOpen: PropTypes.bool.isRequired
};

VideoSection.defaultProps = {
  seekToSecond: undefined,
  isPopupOpen: false,
  popup: undefined,
  promoVideoId: null,
  senderFullName: null,
  giftToken: null
};

export default VideoSection;
