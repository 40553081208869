import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { POPUPS_MAP } from '~layout/Popups';
import PremiumContentBlocker from './popups/PremiumContentBlocker';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '16px',
    alignItems: 'center',
    background: 'linear-gradient(to bottom, rgba(0,0,0, 0.9), rgba(0,0,0, 0.9))',
    [theme.breakpoints.down('xs')]: {
      gap: '8px'
    }
  }
}));

export const IN_VIDEO_POPUPS = [POPUPS_MAP.IN_VIDEO_CONTENT_BLOCKER];

const InVideoBlocker = ({ isPopupOpen, popup }) => {
  const classes = useStyles();

  const renderBlocker = useCallback(() => {
    switch (popup) {
      case POPUPS_MAP.IN_VIDEO_CONTENT_BLOCKER:
        return <PremiumContentBlocker />;
      default:
        return false;
    }
  }, [popup]);

  if (!isPopupOpen) {
    return false;
  }

  return <div className={classes.root}>{renderBlocker()}</div>;
};

InVideoBlocker.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  popup: PropTypes.number
};

InVideoBlocker.defaultProps = {
  popup: null
};

export default InVideoBlocker;
