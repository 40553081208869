import React, { useMemo } from 'react';
import classNames from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import Button from '@material-ui/core/Button';
import Link from '~components/Link';
import { getNavigationPageLink } from '~utils/common-site-helper';
import useConversionFramework from '~hooks/useConversionFramework';
import { getPushToAppExperimentCtaData } from '~utils/experiment-helper';
import { useUserContext } from '~context/UserContext/UserSessionContext';

const useStyles = makeStyles(theme => ({
  headingTitle: {
    marginTop: 0,
    marginBottom: 16,
    color: 'white',
    fontSize: 20,
    fontFamily: 'Inter',
    fontWeight: '600',
    lineHeight: '130%',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
      marginBottom: 8
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      marginBottom: 0
    }
  },
  title: {
    color: '#FFFFFF',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: 'normal',
    maxWidth: '550px',
    marginTop: 0,
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      maxWidth: '500px'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
      maxWidth: '450px'
    }
  },
  ctaContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    rowGap: '16px',
    columnGap: '16px',
    marginBottom: 16,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0
    }
  },
  ctaButton: {
    padding: '14px 30px',
    textTransform: 'none',
    letterSpacing: 0,
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '130%',
    textAlign: 'center',
    borderRadius: '7px',
    [theme.breakpoints.down('xs')]: {
      padding: '10px 15px',
      fontSize: '14px'
    }
  },
  ctaBlueVariant: {
    color: '#FFFFFF',
    backgroundColor: '#1D91FF',
    '&:hover': {
      backgroundColor: '#1D91FF',
      textDecoration: 'none'
    }
  },
  loginText: {
    color: '#FFFFFF',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '130%',
    textAlign: 'center',
    marginTop: 0,
    marginBottom: 6,
    opacity: 0.65,
    '& > a': {
      color: '#FFFFFF',
      textDecoration: 'underline'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px'
    }
  }
}));

const FreeTrialBlocker = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
  const { isMarketingSite } = useConversionFramework();
  const { marketingUser } = useUserContext().session;

  const { text, url } = useMemo(() => getPushToAppExperimentCtaData(), [marketingUser]);

  return (
    <>
      {!isMobile ? (
        <h6 className={classes.headingTitle}>Want to continue watching this video for free?</h6>
      ) : null}
      <div className={classes.ctaContainer}>
        <Button
          component={Link}
          className={classNames(classes.ctaButton, classes.ctaBlueVariant)}
          to={url}
        >
          {text}
        </Button>
      </div>
      {isMobile ? (
        <p className={classes.title}>
          We’re a hardworking non-profit organization committed to delivering exceptional Torah
          content, and we’re funded entirely by our subscribers - people like you. 
          <br />
          <br />
          You can start a free trial and finish watching this video right now for $0. We won’t
          charge you until the trial ends. <b>Zero risk</b>.
        </p>
      ) : (
        <p className={classes.title}>
          Ugh, it stinks to get cut off! We’re not trying to be mean, truly. It’s just that we’re a
          hardworking non-profit organization committed to delivering exceptional Torah content, and
          we’re funded entirely by our subscribers - people like you. 
          <br />
          <br />
          You can start a free trial and finish watching this video right now for $0.
          <br />
          We won’t charge you until the trial ends. <b>Zero risk</b>.
        </p>
      )}

      <div>
        <p className={classes.loginText}>
          Already a member?{' '}
          <Link to={getNavigationPageLink('/login', isMarketingSite)}>Log in</Link>
        </p>
      </div>
    </>
  );
};

export default FreeTrialBlocker;
